import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// data
import { websolutions as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
import ContactBox from '@components/molecules/ContactBox';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ItemBullet from '@components/molecules/items/ItemBullet';
import ListCards from '@components/organisms/ListCards';
import ListClients from '@components/organisms/ListClients';
import ListRelatedProjects from '@components/organisms/ListRelatedProjects';

// assets
import imgSolutions from '@assets/images/solutions.svg';
import iconBullet1 from '@assets/images/bullet_1.svg';
import iconBullet2 from '@assets/images/bullet_2.svg';
import iconBullet3 from '@assets/images/bullet_3.svg';
import imgDigitalProduct from '@assets/images/services/ico-web-digital-product.svg';
import imgUserResearch from '@assets/images/services/ico-web-user-research-proto.svg';
import imgMarketingWebsite from '@assets/images/services/ico-web-marketing-website.svg';
import imgExperienceWebsite from '@assets/images/services/ico-web-experience-website.svg';
import imgProgressiveWebApp from '@assets/images/services/ico-web-mobile-web-app.svg';
import imgEcommerce from '@assets/images/services/ico-web-e-commerce.svg';
import imgResearchAndDevelopment from '@assets/images/services/ico-web-research-and-dev.svg';
import gifFwt from '@assets/images/projects/thumbs/SQUARE-FWT.gif';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const seo = {
	title: 'Agence web suisse – Lausanne, Genève',
	description:
		'Agence web suisse à Lausanne et Genève, création et développement de sites web, e-commerce, applications mobiles pour augmenter votre impact.',
};

const pageClass = 'page-websolutions';

const footerRelatedLinks = [
	{ title: 'Branding', url: '/fr/agence-branding-suisse/' },
	{ title: 'Stratégie', url: '/fr/agence-strategie-digitale/' },
];

const stepsList = [
	{
		image: iconBullet1,
		title: 'Présentez-nous votre projet',
		text: [
			'Toute belle aventure débute par une rencontre.',
			'Discutons de votre réalité afin de définir des objectifs réalistes et de mettre en place un plan d’action stratégique adapté.',
		],
	},
	{
		image: iconBullet2,
		title: 'Développons des solutions',
		text: [
			'Formons une équipe de talents spécifique à votre projet, capable d’imaginer puis de développer une réponse efficace à vos enjeux.',
			<>
				Workshops collaboratifs
				<br />
				Développement digital
				<br />
				Mesure des KPIs
			</>,
		],
	},
	{
		image: iconBullet3,
		title: 'Fêtons votre succès et optimisons-le',
		text: [
			'Fêtons ensemble le succès lors de l’aboutissement de votre projet.',
			'Analysons l’impact de la performance pour en optimiser encore son potentiel.',
		],
	},
];

const cardsList = [
	{
		image: imgDigitalProduct,
		title: 'Service Design & Digital Product',
		alt: 'Service Design & Digital Product',
		text: 'Création de services et de produits centrés sur les besoins des utilisateurs par notre démarche créative et collaborative fondée sur la compréhension des attentes fonctionnelles et émotionnelles des futurs utilisateurs.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgUserResearch,
		title: 'User Research & Prototyping',
		alt: 'User Research & Prototyping',
		text: 'Le comportement de vos utilisateurs est la fondation permettant de diriger les décisions. Nous effectuons de la recherche, du prototypage et du testing pour valider votre démarche.',
	},
	{
		image: imgMarketingWebsite,
		title: 'Marketing Website',
		alt: 'Marketing Website',
		text: "Création de votre site internet avec une forte orientation sur la génération d'impact (KPIs) et en conformité avec la personnalité de votre marque.",
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgExperienceWebsite,
		title: 'Experience Website',
		alt: 'Experience Website',
		text: (
			<>
				Votre site internet doit différencier votre marque. Nous créons
				des sites “haute couture” qui valorisent l’expérience digitale
				avec un dévouement sans faille en faveur de la qualité. Nos 14
				prix internationaux sont notre gage&nbsp;!
			</>
		),
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgProgressiveWebApp,
		title: 'Applications Mobile et Web',
		alt: 'Applications Mobile et Web',
		text: (
			<>
				Design et développement de{' '}
				<dfn>
					<abbr title="Progressive Web App">PWA</abbr>
				</dfn>{' '}
				et app natives capitalisant sur les dernières technologies
				permettent d’engager vos utilisateurs et rendre vos services
				accessibles de manière inégalée.
			</>
		),
	},
	{
		image: imgEcommerce,
		title: 'E-Commerce',
		alt: 'E-Commerce',
		text: 'Conceptualisation, développement et accompagnement digital complet pour générer et optimiser vos ventes en ligne.',
	},
	{
		image: imgResearchAndDevelopment,
		title: 'Recherche & Développement',
		alt: 'Recherche & Développement',
		text: (
			<>
				Il y a ce que nous avons fait, et ce que nous pouvons faire.
				Notre modèle d’équipe est conçu spécialement pour maximiser
				l’exploration et l’innovation.{' '}
				{/* <span
					style={{ fontSize: '.7em', lineHeight: 1.2, opacity: 0.7 }}
				>
					note: à venir, une page de détails valorisant ce qu'on a
					fait (filtres insta, video incrustée, vidéo interactive,…)
					mais aussi notre capacité à relever des challenges
				</span> */}
			</>
		),
		// link: { title: 'Lire plus', url: '/todo' },
	},
];

// --------------------------------
// #endregion
// --------------------------------

const WebsolutionsPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				projectImages: allFile(
					filter: {
						relativePath: {
							in: ["projects/thumbs/PORTRAIT-ECA.jpg"]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 620) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const projectsList = [
		{
			link: '/fr/projets/eca-vaud/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/PORTRAIT-ECA.jpg'
			),
			title: 'ECA Vaud',
			subtitle: 'Conception UI – Design – Développement web - Workshops',
		},
		{
			link: '/fr/projets/fwt-freeride-world-tour/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifFwt,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 496 / 496, // width / height
			},
			title: 'FWT - Freeride World Tour',
			subtitle: 'Conception UI – Design – Développement web - Workshops',
			linkedProjects: [
				{
					link: '/fr/projets/fwt-freeride-world-tour-branding/',
					title: 'Découvrir le FWT rebranding',
				},
			],
		},
	];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/en/swiss-web-agency/', lang: 'en' }]}
			seo={seo}
		>
			<HeroPage
				image={imgSolutions}
				alt="Agence web suisse – Lausanne, Genève"
				title={'Solutions web'}
				subtitle={'Agence web suisse – Lausanne, Genève'}
			/>

			<section
				className="grid text-content text-center"
				aria-label="Introduction"
				data-animation-page
			>
				<h2 className="col-big">
					Établir votre crédibilité
					<br /> Transformer vos visiteurs en clients <br />
					Se démarquer sur le web
					{/* Marquer les esprits */}
				</h2>
				<p className="p-highlight col-big">
					Sites web & Apps sur-mesure
				</p>
			</section>

			<section className="grid grid-content" aria-label="Description">
				<div className="box text-content" data-animation-page>
					<h2>Atteignez vos objectifs business grâce au web</h2>
					<h3>Vous désirez:</h3>
					<ul className="list-two-columns list-default list-brand">
						<li>
							<p>
								Accélérer votre positionnement à l’aide de
								leviers digitaux puissants&nbsp;?
							</p>
						</li>
						<li>
							<p>
								Établir la crédibilité de votre marque auprès de
								votre public cible&nbsp;?
							</p>
						</li>
						<li>
							<p>
								Convertir et fidéliser en offrant une expérience
								digitale hors du commun&nbsp;?
							</p>
						</li>
						<li>
							<p>
								Activer de nouvelles sources de revenus dans un
								marché aux codes fluctuants&nbsp;?
							</p>
						</li>
						<li>
							<p>Récolter et qualifier des leads ciblés&nbsp;?</p>
						</li>
					</ul>
					<ButtonPrimary
						className="element-left"
						href="#message"
						text="Discutons-en"
					/>
				</div>
			</section>

			<section className="grid" aria-label="Clients">
				<div
					className="box text-center text-content"
					data-animation-page
				>
					<p className="uptitle">
						Quelques collaborations fructueuses
					</p>
					<ListClients clientsList={clientsList} />
				</div>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Projects"
				data-animation-page
			>
				<p className="uptitle">Projets Web & Digitaux</p>
				<h2>Découvrez deux succès</h2>
				<ListRelatedProjects
					className={['element-spacing', 'col-big']}
					projectsList={projectsList}
				/>
			</section>

			<section aria-label="Process">
				<SectionTitle title="Processus collaboration" />
				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">Les trois prochaines étapes</p>
						<h2>Comment procéder&nbsp;?</h2>
						<div className="list-three-columns">
							{stepsList.map((step, index) => (
								<ItemBullet
									key={`step-${index}`}
									image={step.image}
									title={step.title}
									text={step.text}
								/>
							))}
						</div>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Contact">
				<ContactBox
					title={
						<>
							Parlez-nous
							<br />
							de votre projet web
						</>
					}
					link={{ title: 'Envoyer un message', url: '#message' }}
					text={
						<>
							Ou par téléphone:{' '}
							<Link href="tel:+41216521818">
								+41 21 652 18 18
							</Link>
						</>
					}
				/>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Services"
				data-animation-page
			>
				<p className="uptitle">Domaines D'Expertise</p>
				<h2>Services web & digitaux</h2>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section>

			<section aria-label="Engagement">
				<SectionTitle title="Point comme nous" />

				<div className="grid">
					<div className="text-content box" data-animation-page>
						<p className="uptitle">Notre engagement en 6 points</p>
						<h2>
							On s’engage en paroles,
							<br />
							mais surtout en actes
						</h2>
						<ul className="list-two-columns">
							<li>
								<h4>C’est pas la taille qui compte</h4>
								<p>
									Que vous soyez un acteur majeur ou une
									start-up qui démarre, nous considérons avant
									tout votre projet. Nous voulons le rêver
									avec vous et participer à sa réussite. C’est
									la source de notre motivation.
								</p>
							</li>
							<li>
								<h4>Sexy mais pas que…</h4>
								<p>
									Nous aimons nous inspirer des dernières
									tendances, mais ce ne sont pas elles qui
									dictent nos choix. Notre travail est dirigé
									par la recherche de performance, de
									singularité et de pérennité.
								</p>
							</li>
							<li>
								<h4>No bullshit</h4>
								<p>
									Nous ne vous vendrons la Lune que si elle
									est atteignable. Nous ne sommes pas des
									vendeurs de rêve, mais des spécialistes aux
									compétences appliquées. Notre langage est
									franc, engagé et surtout sincère. Quand nous
									disons quelque chose c’est que nous y
									croyons.
								</p>
							</li>
							<li>
								<h4>On est pas des divas</h4>
								<p>
									Nous n’allons pas imposer notre vision, ou
									nous vexer si vous pensez différemment. Bien
									sûr, nous avons un point de vue&nbsp;! Nous
									sommes convaincus de nos choix, mais ils
									sont là pour être discutés et surtout pour
									vous permettre de progresser.
								</p>
							</li>
							<li>
								<h4>Culture digitale, mais pas que</h4>
								<p>
									Nous sommes nés avec le digital, comprenons
									ses enjeux et participons à définir ses
									normes. Mais notre terrain de jeu va
									au-delà, nous questionnons votre écosystème
									et votre réalité pour penser des solutions
									qui contribuent à renforcer votre marque à
									long terme, dépassant les frontières du
									digital.
								</p>
							</li>
							<li>
								<h4>Proximité</h4>
								<p>
									La communication se joue aussi dans la
									relation. Nous sommes convaincus de la
									nécessité de nous rencontrer, d’initier
									ensemble des workshops stratégiques. La
									rencontre permet de réaliser des projets
									encore meilleurs.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Prendre contact" id="message">
				<FormMessage
					title={
						<>
							Comment pouvons-nous
							<br />
							vous aider dans votre projet&nbsp;?
						</>
					}
					label="Message"
					text="Pour nous contacter par email"
					contact="hello@superhuit.ch"
				/>
			</section>
		</Layout>
	);
};

export default WebsolutionsPage;
